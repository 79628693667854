import { useEffect, useState } from 'react';

const useIframeCheck = () => {
  const [isIframe, setIsIframe] = useState(false);

  useEffect(() => {
    setIsIframe(window !== window.parent);
  }, []);

  return isIframe;
};

export default useIframeCheck;
