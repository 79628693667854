import { palette } from '@anm/styles/helpers';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  ${palette};

  #modal-root {
    & > div {
      position: relative;
      z-index: 1;
      &:last-child {
        z-index: 10; // up to 10 modals
      }
      &:not(:last-child) > div {
          background: rgba(41,42,43,0.2);
      }
    }
  }

  body, html {
    min-height: 100%;
  }

  body {
    display: block !important;
    color: #292a2b;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;

    * {
      font-family: 'Inter', sans-serif;
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    color: var(--blue_100);
    text-decoration: none;
    cursor: pointer;
  }

  input[type="password"] {
    font-family: Verdana, sans-serif;
    font-size: 12px;
    letter-spacing: 1px;
  }

  input, textarea {
    background-clip: padding-box;
  }
`;
