import {
  all,
  cancel,
  fork,
  take,
  SagaGenerator
} from '@anm/helpers/saga/effects';

import { InjectSagaAction } from '../saga';

function* _start(sagas: SagaGenerator<any>[]) {
  try {
    yield all(sagas);
  } catch (e) {
    console.error('error', e);
  }
}

const createDynamicSaga = (
  changeActionType: string,
  startingSagas: SagaGenerator<any>[]
) => {
  return function* dynamicSaga() {
    let action: InjectSagaAction;
    let rootTask = yield* fork(_start, startingSagas);

    while ((action = yield* take<InjectSagaAction>(changeActionType))) {
      yield cancel(rootTask);
      rootTask = yield* fork(_start, action.sagas);
    }
  };
};

export default createDynamicSaga;
