import localStorage from '@anm/helpers/localStorage';

const storageName = 'timeout_clear';

const setPrevTime = (name: string, time: number) => localStorage().setItem(`${storageName}:${name}`, `${time}`);
const getPrevTime = (name: string) => localStorage().getItem(`${storageName}:${name}`);

const timeoutClear = (name: string, timeout: number) => (clear: () => void) => {
  const prevTime = getPrevTime(name);
  const prevTimeOrDefault = prevTime ? new Date(JSON.parse(prevTime)) : new Date();
  const prevTimeWithTimeout = prevTimeOrDefault.getTime() + timeout;
  const currentTime = new Date().getTime();

  if (!prevTime) {
    setPrevTime(name, currentTime);
  }

  if (prevTimeWithTimeout <= currentTime) {
    clear();
    setPrevTime(name, currentTime);
  }
};

export default timeoutClear;
