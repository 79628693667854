import { createContext, useEffect, useState, FC } from 'react';

import routes from '../../routes';

type HistoryData = {
  type: 'link' | 'back';
  to: string;
  from?: string;
};
type Context = {
  history?: HistoryData[];
};

type TransitionOptions = {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
};

export const RouterContext = createContext({} as Context);

export const RouterContextProvider: FC = ({ children }) => {
  const router = routes.Router;
  const [history, setHistory] = useState<HistoryData[]>([]);

  useEffect(() => {
    const handleRouteChange = (url: string, { shallow }: TransitionOptions) => {
      if (!shallow) {
        setHistory(prevState => [{ to: url, type: 'link', from: routes.Router.router?.asPath }, ...prevState]);
      }
    };

    router.beforePopState(({ as }) => {
      setHistory(prevState => [{ to: as, type: 'back', from: routes.Router.router?.asPath }, ...prevState]);
      return true;
    });

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  return <RouterContext.Provider value={{ history }}>{children}</RouterContext.Provider>;
};
