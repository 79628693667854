import { useAuthInfoCollectByRouter } from '@anm/auth/hooks/useAuthInfoCollect';
import SafeHead from '@anm/components/SafeHeaad';
import CookieAccessModal from '@anm/components/modals/CookieAccessModal';
import ModalRoot from '@anm/components/modals/Modal/ModalRoot';
import ModalNotificationRoot from '@anm/components/modals/NotificationModal/ModalNotificationRoot';
import UserProvider from '@anm/components/user/UserProvider';
import { BetaLabelsProvider } from '@anm/contexts/BetaLabelsContext';
import { LinkProvider } from '@anm/contexts/LinkContext';
import { ConfigUrlsProvider } from '@anm/contexts/UrlsContext';
import { WorkspaceProvider } from '@anm/contexts/WorkspaceContext';
import * as semrush from '@anm/helpers/semrush';
import * as sentry from '@anm/helpers/sentry/client';
import { flushGlobal } from '@anm/helpers/stateful/injectGlobal';
import useDisableContextMenu from '@anm/hooks/useDisableContextMenu';
import useLinktarget from '@anm/hooks/useLinkTarget';
import useRedirectSemrushUser from '@anm/hooks/useRedirectSemrushUser';
import { Features } from '@anm/shared/features';
import { lightTheme } from '@anm/styles/theme';
import { NextReduxWrapperProps } from 'anm-next';
import { NextComponentType } from 'next';
import withRedux from 'next-redux-wrapper';
import appWithI18n from 'next-translate/appWithI18n';
import App, { AppContext } from 'next/app';
import { AppContextType, AppInitialProps } from 'next/dist/shared/lib/utils';
import React, { FC } from 'react';
import { useSelector, Provider } from 'react-redux';
import { compose } from 'recompose';
import { ThemeProvider } from 'styled-components';

import setAppMeta from '../HOCs/setAppMeta';
import withAnalyticSupport from '../HOCs/withAnalyticSupport';
import withAppMeta, { WithAppMetaProps } from '../HOCs/withAppMeta';
import withAsyncRedux from '../HOCs/withAsyncRedux';
import AnmLink from '../components/AnmLink';
import { NavigationProvider } from '../components/Navigation/NavigationContext';
import SemrushDialog from '../components/SemrushAlert';
import vtConfig from '../config';
import { RouterContextProvider } from '../contexts/RouterContext';
import useAuthStatusWatcher from '../hooks/useAuthStatusWatcher';
import useLoadSemrush from '../hooks/useLoadSemrush';
import useRefreshUser from '../hooks/useRefreshUser';
import useTemplateIntercom from '../hooks/useTemplateIntercom';
import useUserpilot from '../hooks/useUserpilot';
import useVisitTracker from '../hooks/useVisitTracker';
import routes from '../routes';
import createStore from '../store/createStore';
import { userSelectors } from '../store/modules/user';
import GlobalStyle from '../styles/GlobalStyle';

const i18configs = require('../i18n.js');

type Props = AppInitialProps & NextReduxWrapperProps & WithAppMetaProps;

const { Router } = routes;

const { urls, showBetaLabels, sentry: sentryConfig, isProd } = vtConfig();

sentry.init(sentryConfig);

const HooksContainer: FC = () => {
  const user = useSelector(userSelectors.selectUserProfile);
  sentry.setUser(user);

  useTemplateIntercom();
  useUserpilot();
  useRefreshUser();
  useLoadSemrush();
  useVisitTracker();
  useAuthStatusWatcher();
  useRedirectSemrushUser(user, isProd);
  useAuthInfoCollectByRouter(Router);
  useLinktarget();
  useDisableContextMenu();

  return <></>;
};

const NextApp: NextComponentType<AppContextType, AppInitialProps, Props> = ({
  store,
  pageProps,
  Component,
  appMeta
}) => {
  const isSemrush = semrush.isSemrushIframe();

  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyle />
      <SafeHead key="next_app">
        <script src={`${urls.wave}api/polyfills`} />
        {flushGlobal('head')}
      </SafeHead>
      <ModalRoot />
      <ModalNotificationRoot />
      <UserProvider apiUrl={urls.api}>
        <NavigationProvider>
          <Provider store={store}>
            <RouterContextProvider>
              <LinkProvider value={{ Link: AnmLink }}>
                <WorkspaceProvider value={{ workspace: appMeta.workspace }}>
                  <ConfigUrlsProvider value={urls}>
                    <BetaLabelsProvider value={{ showLabels: showBetaLabels && !isSemrush }}>
                      <SemrushDialog />
                      <Component {...pageProps} />
                      <HooksContainer />
                      <CookieAccessModal />
                    </BetaLabelsProvider>
                  </ConfigUrlsProvider>
                </WorkspaceProvider>
              </LinkProvider>
            </RouterContextProvider>
          </Provider>
        </NavigationProvider>
      </UserProvider>
    </ThemeProvider>
  );
};

NextApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  return { ...appProps };
};

const composedApp = compose(
  withRedux(createStore, { debug: false }),
  withAsyncRedux,
  setAppMeta,
  withAnalyticSupport,
  withAppMeta
)(NextApp);

export default Features.isTranslations ? appWithI18n(composedApp, i18configs) : composedApp;
