import * as semrush from '@anm/helpers/semrush';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import config from '../../config';
import { semrushActions } from '../../store/modules/semrush';

const {
  semrush: { appId }
} = config();

const useLoadSemrush = () => {
  const dispatch = useDispatch();
  const isSemrush = semrush.isSemrushIframe();

  useEffect(() => {
    if (!isSemrush) return;

    semrush.load();

    (window as any).onSmSdkLoad = async () => {
      await semrush.init(appId);

      const token = await semrush.getAccessToken();

      dispatch(semrushActions.loginSemrush(token));
    };
  }, []);
};

export default useLoadSemrush;
