const setAttributes = <K extends keyof HTMLElementTagNameMap>(
  el: HTMLElementTagNameMap[K],
  attrs: { [key: string]: string }
) => Object.keys(attrs).forEach(key => el.setAttribute(key, attrs[key]));

const setCssText = <K extends keyof HTMLElementTagNameMap>(
  el: HTMLElementTagNameMap[K],
  css: { [key: string]: string }
) =>
  (el.style.cssText = Object.keys(css).reduce(
    (memo, key) => `${memo}${key}: ${css[key]}; `,
    ''
  ));

const createNode = <K extends keyof HTMLElementTagNameMap>(
  tagName: K,
  attrs?: { [key: string]: string },
  css?: { [key: string]: string }
) => {
  const el = document.createElement(tagName);

  attrs && setAttributes(el, attrs);
  css && setCssText(el, css);

  return el;
};

export default createNode;
