import localStorage from '@anm/helpers/localStorage';

const createStateSaver = (storeName: string) => (state: {}) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage().setItem(storeName, serializedState);
  } catch {}
};

const createStateLoader = (storeName: string) => () => {
  try {
    const serializedState = localStorage().getItem(storeName);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

const createStorage = (storeName: string) => {
  const saveState = createStateSaver(storeName);
  const loadState = createStateLoader(storeName);

  return [saveState, loadState] as const;
};

export default createStorage;
