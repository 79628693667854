import { AuthEntry } from '@anm/api/modules/auth';
import isServer from '@anm/helpers/is/isServer';
import { NextReduxWrapperContext } from 'anm-next';
import memoize from 'lodash/memoize';
import { compose, hoistStatics, lifecycle } from 'recompose';

import getProductByDomain from '../helpers/getProductByDomain';
import routes from '../routes';
import { update } from '../store/modules/appMeta';

import withAppMeta, { WithAppMetaProps } from './withAppMeta';
import withInitialProps from './withInitialProps';
import withUser, { WithUserProps } from './withUser';

type RouterEntryQuery = {
  entry?: AuthEntry;
};

const { Router } = routes;

const setAppMeta = ({ ctx: { query, store, asPath, ...other } }: NextReduxWrapperContext) => {
  const domain = isServer() ? other!.req!.headers.host : location.host;
  const path = `https://${domain}${asPath}`;

  store.dispatch(
    update({
      path,
      entry: query.entry as AuthEntry,
      product: getProductByDomain(domain!),
      routerParams: query
    })
  );
};

const memoizedMemoize = memoize(f => memoize(f, ({ entry }: RouterEntryQuery) => entry));

function componentDidUpdate(this: { props: WithUserProps & WithAppMetaProps }) {
  const {
    user: { profile },
    update
  } = this.props;
  const { entry }: RouterEntryQuery = Router.query as any;

  if (!profile && !entry) return;

  const memoizedUpdateAppMeta = memoizedMemoize(update);
  const userEntry = profile?.meta?.entry;

  memoizedUpdateAppMeta({ entry: entry || userEntry });
}

const SetAppMetaByUserMeta = compose<any, any>(
  withUser,
  withAppMeta,
  lifecycle<WithUserProps & WithAppMetaProps, {}>({ componentDidUpdate })
);

export default compose(withInitialProps(setAppMeta), hoistStatics(SetAppMetaByUserMeta));
