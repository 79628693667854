import { trackVisit } from '@anm/analytic';
import initAnalytic, {
  transformWaveConfigs
} from '@anm/analytic/helpers/initAnalytic';
import memoizedTrackVisitPage from '@anm/analytic/helpers/memoizedTrackVisitPage';
import { AnimatronProduct } from '@anm/api/modules/auth';
import sleep from '@anm/helpers/sleep';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import vtConfig from '../config';
import routes from '../routes';
import { appMetaSelectors } from '../store/modules/appMeta';
import { authSelectors } from '../store/modules/auth';
import { userSelectors } from '../store/modules/user';

const { Router } = routes;

const trackGuestVisit = (product: AnimatronProduct) =>
  trackVisit({
    product,
    userProfile: null
  });

const isTrackAllowed = (route: string = '') => {
  const blackList = ['wave-event', 'social-auth-callback', 'video'];

  return !blackList.some(item => `/${item}` === route);
};

const trackVisitPageByRoute = () => {
  const { route, pathname } = Router.router!;

  if (!isTrackAllowed(route)) return;

  memoizedTrackVisitPage(pathname);
};

const useGuestVisitTrack = () => {
  const product = useSelector(appMetaSelectors.selectAppMetaProduct);
  const isUserLogged = useSelector(authSelectors.isUserLogged);
  const { profile } = useSelector(userSelectors.selectUser);

  useEffect(() => {
    if (!profile && !isUserLogged) {
      trackGuestVisit(product);
    }
  });
};

const useAnalyticInit = () => {
  const product = useSelector(appMetaSelectors.selectAppMetaProduct);

  useEffect(() => {
    const analyticConfigs = vtConfig().analytics;
    const transformedConfigs = transformWaveConfigs(analyticConfigs, product);

    initAnalytic(transformedConfigs, product);
  }, []);
};

const useVisitPageTrack = () => {
  useEffect(() => {
    (async () => {
      await sleep(0);
      trackVisitPageByRoute();
    })();

    Router.router!.events.on('routeChangeComplete', trackVisitPageByRoute);

    return () => {
      Router.router!.events.off('routeChangeComplete', trackVisitPageByRoute);
    };
  }, []);
};

const useVisitTracker = () => {
  useAnalyticInit();
  useGuestVisitTrack();
  useVisitPageTrack();
};

export default useVisitTracker;
