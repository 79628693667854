import * as userpilot from '@anm/helpers/userpilot';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import routes from '../routes';
import { authSelectors } from '../store/modules/auth';
import { userSelectors } from '../store/modules/user';

const { Router } = routes;

const useUserpilot = () => {
  const profile = useSelector(userSelectors.selectUserProfile);
  const isNewUser = useSelector(authSelectors.isNewUser);

  const isSemrush = profile?.meta?.entry === 'SEMRUSH';
  const canNotLoadSemrush = !profile?.subscriptionDetails || isSemrush;

  const updateUserpilot = () => profile?.userId && userpilot.setWebsiteUser(profile, isNewUser);

  const handleRouteChange = () => userpilot.updateUserpilot();

  useEffect(() => {
    if (canNotLoadSemrush) return;

    Router.events.on('routeChangeComplete', handleRouteChange);

    () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [canNotLoadSemrush]);

  useEffect(() => {
    if (canNotLoadSemrush) return;
    
    (async () => {
      await userpilot.load();
      updateUserpilot();
    })();
  }, [canNotLoadSemrush]);
};

export default useUserpilot;
