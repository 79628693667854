import * as analytic from '@anm/analytic';
import LodashCompose from 'lodash/fp/compose';
import { hoistStatics, lifecycle, InferableComponentEnhancer } from 'recompose';

import vtConfig from '../config';
import detectUserActivity from '../helpers/detectUserActivity';
import timeoutClear from '../helpers/timeoutClear';

const timeout = vtConfig().isProd ? 2 * 60 * 60 * 1000 : 3 * 60 * 1000;

const analyticTimeoutClear = (clear: () => void) => () =>
  timeoutClear('analytic', timeout)(clear);
const runTimeout = LodashCompose(detectUserActivity, analyticTimeoutClear);

let clearAnalyticStorage: () => void;
function componentDidMount() {
  clearAnalyticStorage = runTimeout(analytic.clearVisitStore);
}

function componentWillUnmount() {
  clearAnalyticStorage();
}

export default hoistStatics(lifecycle({
  componentDidMount,
  componentWillUnmount
}) as InferableComponentEnhancer<{}>);
