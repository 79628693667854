import hasAuthAccess from '@anm/auth/helpers/hasAuthAccess';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { userActions } from '../store/modules/user';

const useRefreshUser = () => {
  const dispatch = useDispatch();
  const isLoggedIn = hasAuthAccess();

  useEffect(() => {
    if (!isLoggedIn) return;

    dispatch(userActions.getUser());
  }, [isLoggedIn]);
};

export default useRefreshUser;
