import { USERPILOT_KEY } from '@anm/constants/services';
import getCurrentSubscription from '@anm/helpers/getCurrentSubscription';
import { User } from 'user';

declare global {
  interface Window {
    userpilot: any;
  }
}

let isLoaded = false;

export const load = () =>
  new Promise(resolve => {
    /* tslint:disable */
    (function(d: any, e: any, v: any, t?: any, s?: any) {
      if (isLoaded) return resolve('OK');
      t = d.createElement(e);
      t.type = 'text/javascript';
      t.async = true;
      t.src = v;
      t.onload = function() {
        resolve('OK');
      };
      s = d.getElementsByTagName('body')[0];
      s.append(t);
      isLoaded = true;
    })(document, 'script', `https://js.userpilot.io/${USERPILOT_KEY}/latest.js`);
  });

export const setAnonymous = () => window.userpilot.anonymous();

export const setWebsiteUser = (user: User, isNewUser: boolean) => {
  if (!window.userpilot || !user) return;

  const { username, email, userId, created, embedStats, subscriptionDetails } = user;

  const currentSubscription = getCurrentSubscription(subscriptionDetails);
  const isPaidSubscription = !!(currentSubscription.monthlyPrice || currentSubscription.annualPrice);
  const lastByte = parseInt(userId.substring(userId.length - 2), 16);
  const isFreeTrial = lastByte > 128;

  window.userpilot.identify(userId, {
    email,
    name: username,
    created_at: `${created}`,
    plan: currentSubscription.name,
    'embedded videos': embedStats?.data?.used || 0,
    period: currentSubscription.billingPeriod,
    'paid subscription active': isPaidSubscription,
    ...(isNewUser && { FreeTrial: isFreeTrial })
  });
};

export const setStreamingUser = (user: User) => {
  const { userpilot } = window;

  if (!userpilot || !user) return;

  const { username, email, userId } = user;

  userpilot.identity?.(userId, { email, name: username });
};

export const updateUserpilot = () => window.userpilot?.reload();
