import React from 'react';

type UrlNames =
  | 'cdn'
  | 'api'
  | 'apiWave'
  | 'wave'
  | 'editor'
  | 'studio'
  | 'animatronRoot'
  | 'calendarData'
  | 'studioEditor';

type Urls = { [key in UrlNames]: string };

export const ConfigUrlsContext = React.createContext({} as Urls);

export const ConfigUrlsProvider = ConfigUrlsContext.Provider;
