import { combineReducers, Reducer, ReducersMapObject } from 'redux';

import appMetaReducer from './modules/appMeta';
import appNotificationReducer from '@anm/store/modules/appNotifications';

import authReducer from './modules/auth';
import notificationReducer from './modules/notification';
import videoProjectReducer from './modules/project';
import userReducer from './modules/user';
import { ApplicationAsyncState, ApplicationStaticState, AppStore } from './types';
import teamReducer from './modules/team';

export const createReducer = (asyncReducers = {}): Reducer<ApplicationStaticState & Partial<ApplicationAsyncState>> =>
  combineReducers<ApplicationStaticState & Partial<ApplicationAsyncState>>({
    team: teamReducer,

    auth: authReducer,
    user: userReducer,
    project: videoProjectReducer,
    appMeta: appMetaReducer,
    notification: notificationReducer,
    appNotifications: appNotificationReducer,
    ...asyncReducers
  });

export const injectAsyncReducers = (
  store: AppStore<ApplicationStaticState & Partial<ApplicationAsyncState>>,
  asyncReducers: ReducersMapObject<Partial<ApplicationAsyncState>, any> = {}
) => {
  Object.keys(asyncReducers).reduce((acc, name: keyof Partial<ApplicationAsyncState>) => {
    if (!store.asyncReducers[name]) {
      acc[name] = asyncReducers[name];
    }
    return acc;
  }, store.asyncReducers);

  store.replaceReducer(createReducer(store.asyncReducers));
};
