import api from '@anm/api';
import { setAuthToken } from '@anm/auth/helpers/authToken';
import asyncEntity from '@anm/helpers/redux/asyncEntity';
import { call, take, fork } from '@anm/helpers/saga/effects';
import { takeType } from '@anm/helpers/saga/typesafe-actions';

import { fetchUser } from '../user/saga';

import { semrushActions } from '.';

const loginSemrushRequest = (jwt: string) => api().auth.loginSemrush(jwt);
const loginSemrush = asyncEntity(semrushActions.loginSemrushAsync, loginSemrushRequest);

function* watchSemrushLogin() {
  while (true) {
    const { payload: jwtToken } = yield* take(takeType(semrushActions.loginSemrush));

    yield* call(loginSemrush, jwtToken);
  }
}

function* watchSemrushLoginSuccess() {
  while (true) {
    const {
      payload: { encryptedToken }
    } = yield* take(takeType(semrushActions.loginSemrushAsync.success));
    yield* fork(fetchUser);

    yield* fork(setAuthToken, encryptedToken);
  }
}

const authSagaWatchers = () => [call(watchSemrushLogin), call(watchSemrushLoginSuccess)];

export default authSagaWatchers;
