import Dialog from '@anm/components/modals/Dialog';
import Confirm from '@anm/components/notification/Confirm';
import browserStore from '@anm/helpers/browserStore';
import { useEffect, useState, FC } from 'react';
import { useSelector } from 'react-redux';

import { userSelectors } from '../../store/modules/user';

const ALERT_KEY = 'Semrush-alert-last-opened';

const getDateKey = (time = Date.now()) => {
  const date = new Date(time);

  return `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
};

const SemrushDialog: FC = () => {
  const isSemrushUser = useSelector(userSelectors.isSemrushUser);

  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (!isSemrushUser) return;

    const lastDatePressedOk = browserStore.get<string>(ALERT_KEY);
    const canOpen = lastDatePressedOk ? getDateKey() !== getDateKey(+lastDatePressedOk) : true;

    canOpen && setOpen(true);
  }, [isSemrushUser]);

  const closeModal = (isOkPressed?: boolean) => {
    setOpen(false);
    isOkPressed && browserStore.set(ALERT_KEY, Date.now());
  };

  if (!isOpen) return null;

  return (
    <Dialog noMinWidth dialogBackground="#fff0f7" onModalClose={() => closeModal(false)}>
      <Confirm
        title="Notice: App Closing on September 11th"
        renderAdditionalBlock={() => (
          <p>
            The Video Marketing Platform app will permanently close on September 11, 2024. We recommend you download any
            important files before this date, as access will no longer be available. Thank you for your understanding
            and apologize for any inconvenience. If you have any questions, please feel free to reach out to &nbsp;
            <a href="https://www.semrush.com/kb/support/" target="_blank">
              https://www.semrush.com/kb/support/
            </a>
          </p>
        )}
        buttonLeft={{
          size: 'large',
          variant: 'light-blue',
          text: 'Ok',
          onClick: () => closeModal(true)
        }}
      />
    </Dialog>
  );
};

export default SemrushDialog;
