import { ApiError } from '@anm/api';
import { createAction, createAsyncAction } from '@anm/helpers/saga/typesafe-actions';

import { AuthResponse } from './../auth/actions';

export const loginSemrush = createAction('wave.video/auth/LOGIN_SEMRUSH')<string>();

export const loginSemrushAsync = createAsyncAction(
  'wave.video/auth/LOGIN_SEMRUSH_REQUEST',
  'wave.video/auth/LOGIN_SEMRUSH_SUCCESS',
  'wave.video/auth/LOGIN_SEMRUSH_FAILURE'
)<{ provider: string; jwt?: string }, AuthResponse, ApiError>();
