const websiteData = require('@anm/translations/data_website.json');
const locales = websiteData.langs.map(l => l.code);
const { Features } = require('@anm/shared/features');

module.exports = Features.isTranslations
  ? {
      locales,
      defaultLocale: 'en',
      defaultNS: 'translation',
      loader: false,
      keySeparator: '-',
      loggerEnvironment: 'both',
      pages: {
        '*': ['translation']
      },
      loadLocaleFrom: (lang, ns) => {
        try {
          return import(`./locales/${lang}/${ns}.json`).then(m => m.default);
        } catch (e) {
          console.log(`Load locales error:`, e);
        }
      }
    }
  : {};
