import { ANM_LOCAL_STORAGE } from '@anm/constants/auth';
import createStatePersistor from '@anm/helpers/redux/persistState';

import { appMetaPersistConfig } from './modules/appMeta';
import { authPersistConfig } from './modules/auth/reducer';
import { notificationPersistConfig } from './modules/notification';
import { userPersistConfig } from './modules/user';

export const { getPersistedState, persistStore } = createStatePersistor({
  storeName: ANM_LOCAL_STORAGE,
  modules: [
    authPersistConfig,
    userPersistConfig,
    appMetaPersistConfig,
    notificationPersistConfig
  ]
});
