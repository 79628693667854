import TitleBlockWrapper from '@anm/components/TitleBlock/Wrapper';
import Dialog from '@anm/components/modals/Dialog';
import { flexCenter } from '@anm/styles/helpers';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  margin-top: 20px;
  ${flexCenter}
`;

export const ReadMore = styled.div`
  margin: 10px 0;
  color: #2294df;
  font-size: 14px;
  cursor: pointer;
`;

export const Text = styled.div`
  margin-top: 20px;
  font-size: 16px;
  line-height: 24px;
`;

export const Info = styled.span`
  font-size: 14px;
`;

export const Link = styled.a`
  margin-top: 10px;
  display: inline-block;
  color: var(--blue_100);
  text-decoration: underline;
`;

export const BlackLink = styled(Link)`
  color: #292a2b;
`;
export const PaddingLink = styled(Link)`
  margin-left: 30px;
`;

export const EdgeTitle = styled(Link)`
  display: inline-block;
`;

export const DialogWrapper = styled(Dialog)`
  ${TitleBlockWrapper} {
    max-width: 450px;
  }
`;
