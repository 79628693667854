import { isProd } from '@anm/shared/mode';
import * as semrush from '@anm/helpers/semrush';
import { useEffect } from 'react';

import { WAVE_PROD, WAVE_TEST } from './../../constants/domains';

const waveUrl = isProd ? WAVE_PROD : WAVE_TEST;

const getLink = (e: Event) => {
  const link = (e.target as HTMLElement).closest('a');

  const isAbsoluteLinkToWave = link?.getAttribute('href')?.includes(waveUrl) && !link?.href.includes('/blog');

  return { link, isAbsoluteLinkToWave };
};

const useLinkTarget = () => {
  const isSemrush = semrush.isSemrushIframe();

  const onDocumentClick = (e: KeyboardEvent) => {
    const isControlPressed = e.metaKey || e.ctrlKey;
    const { link, isAbsoluteLinkToWave } = getLink(e);
    const dataOmitValue = link?.getAttribute('data-omit');
    const isTargetBlank = link?.getAttribute('target') === '_blank';
    const canSkipCheck = dataOmitValue === 'skipCheck';

    if (link && !canSkipCheck) {
      if (isTargetBlank) {
        e.preventDefault();

        window.open(link.href, '_self');
      }

      if ((isAbsoluteLinkToWave || isControlPressed) && !dataOmitValue) {
        e.preventDefault();
        window.open(link.href.replace(waveUrl, '/'), '_self');
      }
    }
  };

  const onMouseWheelClick = (e: MouseEvent) => {
    const { link, isAbsoluteLinkToWave } = getLink(e);

    const isProjectTab = !!link?.getAttribute('data-omit');

    if (link && e.which === 2) {
      e.preventDefault();

      if (isProjectTab) return;

      if (isAbsoluteLinkToWave) {
        link.href = link.href.replace(waveUrl, '/');
      }

      window.open(link.href.replace(waveUrl, '/'), '_self');
    }
  };

  useEffect(() => {
    if (!isSemrush) return;

    document.body.addEventListener('click', onDocumentClick);
    document.body.addEventListener('auxclick', onMouseWheelClick);

    return () => {
      document.body.removeEventListener('click', onDocumentClick);
      document.body.removeEventListener('auxclick', onMouseWheelClick);
    };
  }, [isSemrush]);
};

export default useLinkTarget;
