import checkCookieAccess from '@anm/helpers/iframe/checkCookieAccess';
import { useEffect, useState } from 'react';

const useCheckCookieAccess = () => {
  const [hasAccess, setHasAccess] = useState(true);

  useEffect(() => {
    const removeListener = checkCookieAccess(setHasAccess);
    () => removeListener();
  }, []);

  return hasAccess;
};

export default useCheckCookieAccess;
