import { ANIMATRON_ASSETS_PROD } from '@anm/constants/domains';
import createIframes from '@anm/helpers/iframe/createIframes';
import { ReturnFunctionArg } from 'helpers';

const createMessageListener = (onMessage: (isSupported: boolean) => void) => {
  const receiveMessage = ({ data }: MessageEvent) => {
    data === 'cookie_access_supported'
      ? onMessage(true)
      : data === 'cookie_access_NOT_supported' && onMessage(false);
  };

  window.addEventListener('message', receiveMessage, false);

  return () => {
    window.removeEventListener('message', receiveMessage, false);
  };
};

const appendIframe = () => {
  const iframesContainer = createIframes([
    `${ANIMATRON_ASSETS_PROD}cookie-access/index.html`,
  ]);
  document.body?.appendChild(iframesContainer);
};

const checkCookieAccess = (
  onMessage: ReturnFunctionArg<typeof createMessageListener>[0]
) => {
  const removeMessageListener = createMessageListener(onMessage);
  appendIframe();

  return removeMessageListener;
};

export default checkCookieAccess;
