import asyncEntityHandlers from '@anm/helpers/redux/asyncEntityHandlers';
import reduceFactory from '@anm/helpers/redux/reduceFactory';
import { Reducer } from 'redux';
import {  ResponseState } from '@anm/api';
import { ActionType } from '@anm/helpers/saga/typesafe-actions';


import { semrushActions } from '.';

export type SemrushActions = ActionType<typeof semrushActions>;

const initialState: ResponseState = {
  isError: false,
	isPending: false,
	error: null
};

const semrushReducer: Reducer<ResponseState, SemrushActions> = (state = initialState, action) => {
  switch (action.type) {
    default:
      return reduceFactory(initialState, {
        ...asyncEntityHandlers<ResponseState>(semrushActions.loginSemrushAsync)
      })(state, action);
  }
};

export default semrushReducer;
