const createActivityEvent = (cb: () => void) => {
  const events = ['click'];

  events.forEach((eventName: string) =>
    document.addEventListener(eventName, cb)
  );

  return () =>
    events.forEach((eventName: string) =>
      document.removeEventListener(eventName, cb)
    );
};

const detectUserActivity = (doActive: () => void) =>
  createActivityEvent(doActive);

export default detectUserActivity;
