import pick from 'lodash/fp/pick';
import { connect } from 'react-redux';

import { userActions, userSelectors } from '../store/modules/user';
import { ApplicationState } from '../store/types';

export type WithUserProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const mapStateToProps = ({ user }: ApplicationState) => ({
  user,
  isUserStaff: () => userSelectors.isUserStaff(user.profile)
});

const mapDispatchToProps = pick<typeof userActions, keyof typeof userActions>([
  'getUser',
  'updateUser',
  'deleteAccount',
  'changePassword'
])(userActions);

export default connect(mapStateToProps, mapDispatchToProps);
