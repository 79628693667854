import isString from 'lodash/fp/isString';
import { useEffect, useState } from 'react';

const useIframeAttributes = (attributeNames: string[]) => {
  const [attributeValues, setAttributeValues] = useState<
    (boolean | string | null)[]
  >();

  useEffect(() => {
    const values = attributeNames.reduce((acc, attrName) => {
      const value = window.frameElement?.getAttribute(`data-${attrName}`);
      const parsedValue =
        value === '' || (isString(value) && JSON.parse(value)) || value;

      return [...acc, parsedValue];
    }, []);

    setAttributeValues(values);
  }, []);

  return attributeValues;
};

export default useIframeAttributes;
