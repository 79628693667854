import { AnimatronProduct } from '@anm/api/modules/auth';
import get from 'lodash/get';

import vtConfig from '../config';

const { urls } = vtConfig();
const domainProductMap: { [key: string]: AnimatronProduct } = {
  [urls.wave]: 'WAVE',
  [urls.studio]: 'STUDIO'
};
const getProductByDomain = (domain: string) =>
  Object.keys(domainProductMap)
    .filter(key => key.includes(domain))
    .map<AnimatronProduct>(get.bind(null, domainProductMap))[0];

export default getProductByDomain;
