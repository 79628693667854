import useIntercom from '@anm/hooks/useIntercom';
import { useSelector } from 'react-redux';

import config from '../config';
import { appMetaSelectors } from '../store/modules/appMeta';
import { userSelectors } from '../store/modules/user';

const {
  support: {
    intercom: { ids, enable }
  }
} = config();

const useTemplateIntercom = () => {
  const product = useSelector(appMetaSelectors.selectAppMetaProduct);
  const user = useSelector(userSelectors.selectUserProfile);

  useIntercom({
    id: ids![product],
    user,
    enable,
    product
  });
};

export default useTemplateIntercom;
