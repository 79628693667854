import isServer from '@anm/helpers/is/isServer';
import { CallEffect, ForkEffect } from '@redux-saga/core/effects';
import { Store } from 'redux';
import { SagaGenerator } from 'typed-redux-saga/dist';

import createDynamicSaga from './helpers/createDynamicSaga ';
import { analyticsSaga } from './modules/analytics';
import { appMetaSaga } from './modules/appMeta';
import { authSaga } from './modules/auth';
import { projectSaga } from './modules/project';
import { teamSaga } from './modules/team';
import { userSaga } from './modules/user';

const rootSagas = (): SagaGenerator<any>[] => [
  ...teamSaga(),
  ...appMetaSaga(),
  ...authSaga(),
  ...userSaga(),
  ...projectSaga(),
  ...analyticsSaga()
];

const INJECT_SAGAS = 'wave.video/INJECT_SAGAS';

export type InjectSagaAction = {
  type: typeof INJECT_SAGAS;
  sagas: SagaGenerator<any>[];
};

export const createSaga = () => createDynamicSaga(INJECT_SAGAS, rootSagas());

export const injectSaga = (() => {
  let lastId: string | undefined;

  return async (store: Store, sagas: () => (CallEffect | ForkEffect)[] = () => [], id?: string) => {
    if (lastId !== id || id === undefined || isServer()) {
      lastId = id;
      return store && sagas().length && store.dispatch({ sagas: [...rootSagas(), ...sagas()], type: INJECT_SAGAS });
    }
  };
})();
