import { useEffect } from 'react';
import * as semrush from '@anm/helpers/semrush';

const useDisableContextMenu = () => {
  const isSemrush = semrush.isSemrushIframe();

  const disableMenu = (e: Event) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (!isSemrush) return;

    document.body.addEventListener('contextmenu', disableMenu);

    return () => {
      document.body.addEventListener('contextmenu', disableMenu);
    };
  }, [isSemrush]);
};

export default useDisableContextMenu;
