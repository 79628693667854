import createNode from '@anm/helpers/createNode';

const createIframe = (url: string) =>
  createNode(
    'iframe',
    {
      src: url
    },
    {
      width: '1px !important',
      height: '1px !important',
      position: 'fixed !important',
      visibility: 'hidden !important',
      'pointer-events': 'none !important'
    }
  );

const removePrevContainer = (containerName: string) => {
  const prevContainers = document.querySelectorAll(`.${containerName}`);
  prevContainers.forEach(container => container.remove());
};

const createContainer = (containerName: string) => {
  const container = document.createElement('div');
  container.classList.add(containerName);
  return container;
};

const createIframes = (urls: string[], containerName = 'iframe-container') => {
  removePrevContainer(containerName);
  const iframesContainer = createContainer(containerName);
  const iframes = urls.map(createIframe);

  iframes.forEach(iframe => iframesContainer?.appendChild(iframe));

  return iframesContainer;
};

export default createIframes;
