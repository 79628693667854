import { AnmLinkProps } from '@anm/contexts/LinkContext';
import { FC } from 'react';

import routes from '../../routes';

const { Link } = routes;

const AnmLink: FC<AnmLinkProps> = ({ route, params, children, scroll }) => (
  <Link {...{ route, params, scroll }}>
    <a>{children}</a>
  </Link>
);

export default AnmLink;
