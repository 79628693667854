import TitleBlock from '@anm/components/TitleBlock';
import Button from '@anm/components/buttons/Button';
import useIframeAttributes from '@anm/hooks/dom/useIframeAttributes';
import useIframeCheck from '@anm/hooks/dom/useIframeCheck';
import useCheckCookieAccess from '@anm/hooks/useCheckCookieAccess';
import { useCallback, FC } from 'react';

import ReadMoreContent from './ReadMoreContent';
import { ButtonContainer, DialogWrapper, Text } from './Wrapper';

export type CookieAccessModalProps = {};

const COOKIE_ACCESS_IFRAME_ATTRIBUTE = 0;
const COOKIE_ACCESS_IFRAME_ATTRIBUTE_NAME = 'disable-check-cookie-access';

const CookieAccessModal: FC<CookieAccessModalProps> = () => {
  const hasAccess = useCheckCookieAccess();
  const reload = useCallback(() => window.location.reload(), []);
  const isOpenInIframe = useIframeCheck();
  const iframeAttributes = useIframeAttributes([COOKIE_ACCESS_IFRAME_ATTRIBUTE_NAME]);

  if (hasAccess || !isOpenInIframe || !iframeAttributes || iframeAttributes[COOKIE_ACCESS_IFRAME_ATTRIBUTE]) {
    return null;
  }

  return (
    <DialogWrapper onModalClose={reload} onSubmit={reload}>
      <TitleBlock
        size="medium"
        title="Cookies are disabled in your browser"
        description="To use Wave.video, you have to enable cookies in your web browser settings."
      />

      <ReadMoreContent />
      <Text>
        If you see a cookie icon on the right side of your address bar, click that icon to allow third-party cookies.
      </Text>

      <ButtonContainer>
        <Button variant="light-blue" size="medium" onClick={reload}>
          Done
        </Button>
      </ButtonContainer>
    </DialogWrapper>
  );
};

export default CookieAccessModal;
