import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import omit from 'lodash/fp/omit';
import set from 'lodash/fp/set';
import createThrottle from 'lodash/fp/throttle';
import { Store } from 'redux';

import createStorage from './createStorage';

export type PersistModule = {
  path: string;
  blackList?: string[];
};
type PersistSettings = {
  storeName: string;
  modules: PersistModule[];
};

const throttle = createThrottle(1000);

const createStateMerge = (modules: PersistModule[]) => <S extends {}>(resultState: S, persistedState: S) =>
  modules.reduce((acc, { path, blackList = [] }) => {
    const getState = get(path);
    const state = getState(persistedState);
    const filteredState = omit(blackList)(state);
    const prevState = getState(resultState);
    const newState = { ...prevState, ...filteredState };

    return isEmpty(newState) ? acc : set(path)(newState, acc);
  }, resultState);

const createStatePersistor = ({ storeName, modules }: PersistSettings) => {
  const [saveState, loadState] = createStorage(storeName);
  const mergeState = createStateMerge(modules);

  const persistStore = (store: Store) => {
    store.subscribe(
      throttle(() => {
        saveState(mergeState({}, store.getState()));
      })
    );
  };

  const getPersistedState = <S extends {}>(initialState: S = {} as S) => {
    const persistState = loadState();

    return mergeState(initialState, persistState);
  };

  return { getPersistedState, persistStore };
};

export default createStatePersistor;
