import isServer from '@anm/helpers/is/isServer';
import { waitAsync } from '@anm/helpers/saga/effects';
import { NextAppWrapper, NextReduxWrapperContext, NextReduxWrapperProps } from 'anm-next';
import { AppProps } from 'next/app';
import React, { Component } from 'react';

import vtConfig from '../config';
import { injectAsyncReducers } from '../store/reducer';
import { injectSaga } from '../store/saga';

const { nextDevMode } = vtConfig();

let isReady = false;

const withAsyncRedux = <P extends object>(WrappedComponent: NextAppWrapper<P>) =>
  class WrappedApp extends Component<NextReduxWrapperProps & AppProps> {
    static displayName = `withAsyncRedux(${WrappedComponent.displayName || WrappedComponent.name || 'App'})`;

    static async getInitialProps(props: NextReduxWrapperContext) {
      const { ctx, Component } = props;
      const { store } = ctx;
      const { asyncSagas, asyncReducers, id } = Component;

      await waitAsync({ isDebug: nextDevMode });

      const injectCurrentSaga = () => injectSaga(store, asyncSagas, id);
      injectCurrentSaga();
      injectAsyncReducers(store, asyncReducers);

      const page = WrappedComponent.getInitialProps
        ? await WrappedComponent.getInitialProps.call(WrappedComponent, props)
        : ({} as any);

      isServer() && (await waitAsync({ isDebug: nextDevMode }));

      await page.pageProps.onAsyncDone?.();

      return { ...page };
    }

    constructor(props: NextReduxWrapperProps & AppProps) {
      super(props);

      if (isServer() || isReady) return;

      const { store, Component } = props;
      const { asyncSagas, asyncReducers, id } = Component;

      injectSaga(store, asyncSagas, id);
      injectAsyncReducers(store, asyncReducers);

      isReady = true;
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

export default withAsyncRedux;
