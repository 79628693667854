import noop from '@anm/helpers/noop';
import { NextReduxWrapperContext } from 'anm-next';
import compose from 'lodash/fp/compose';
import { NextPage, NextPageContext } from 'next';
import App, { AppContext } from 'next/app';
import Document, { DocumentContext } from 'next/document';
import Error from 'next/error';

type NextPages = NextPage | typeof Document | typeof App | typeof Error;
type NextContexts =
  | DocumentContext
  | AppContext
  | NextPageContext
  | NextReduxWrapperContext;

const reverse = <T extends any[]>(canReverse: boolean) => (arr: T) =>
  canReverse ? arr.reverse() : arr;

const runPromises = <T extends {}, F extends (...args: any[]) => any>(
  props: T
) => (funcs: F[]) => Promise.all(funcs.map(f => f(props)));

const flattenArrObjects = <T extends {}[]>(arr: T) =>
  arr.reduce((acc, item) => ({ ...acc, ...item }), {});

const withInitialProps = (
  getInitialPropsMiddleware: (props: NextContexts) => any,
  shouldReverse = false
) => <T extends NextPages>(BaseComponent: T) => {
  const originGetInitialProps = BaseComponent.getInitialProps || noop;

  BaseComponent.getInitialProps = async (props: NextContexts): Promise<any> => {
    const responses = await compose(
      runPromises(props),
      reverse(shouldReverse)
    )([originGetInitialProps, getInitialPropsMiddleware]);

    return flattenArrObjects(responses);
  };

  return BaseComponent;
};

export default withInitialProps;
