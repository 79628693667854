import { setAnon } from '@anm/auth/helpers/authAnon';
import * as authStatusWatcher from '@anm/auth/helpers/authStatusWatcher';
import resetAppState from '@anm/auth/helpers/resetAppState';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import vtConfig from '../config';
import { authActions } from '../store/modules/auth';
import { userSelectors } from '../store/modules/user';

const { cookieName } = vtConfig().auth;

const useAuthStatusWatcher = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUserProfile);
  const isGuest = useSelector(userSelectors.selectIsUserGuest);

  const handleLogin = (token: string) => {
    dispatch(authActions.loginWithToken(token));
  };

  const handleLogout = () => dispatch(authActions.logout());
  const handleResetAppState = () => {
    dispatch(authActions.resetAuthState());
    resetAppState();
  };

  useEffect(() => {
    const clear = authStatusWatcher.run({
      cookieName,
      onLogin: handleLogin,
      onLogout: handleLogout,
      onReset: handleResetAppState
    });

    return () => {
      clear();
    };
  }, []);

  useEffect(() => {
    user && setAnon(isGuest);
  }, [isGuest, !!user]);
};

export default useAuthStatusWatcher;
