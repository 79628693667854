import compose from 'lodash/fp/compose';
import { createStore as createReduxStore } from 'redux';
import createSagaMiddleware, { Saga } from 'redux-saga';

import createEnhancer from './helpers/createEnhancer';
import { getPersistedState, persistStore } from './persistor';
import { createReducer } from './reducer';
import { createSaga } from './saga';
import { ApplicationState, AppStore } from './types';

const selectStore = ({ store }: { store: AppStore }) => store;

const withSagaTask = ({ store, sagaMiddleware, ...props }: ReturnType<typeof createStore>) => {
  store.runSagaTask = () => {
    store.sagaTask = sagaMiddleware.run(createSaga() as Saga);
  };
  store.runSagaTask();

  return {
    store,
    ...props
  };
};

const withAsyncReducers = ({ store, ...props }: ReturnType<typeof createStore>) => {
  persistStore(store);
  store.asyncReducers = store.asyncReducers || {};
  return {
    store,
    ...props
  };
};

const createStore = ({ initialState, sagaMiddleware, ...props }: ReturnType<typeof getSagaMiddleware>) => ({
  sagaMiddleware,
  store: createReduxStore(createReducer(), getPersistedState(initialState), createEnhancer(sagaMiddleware)) as AppStore,
  ...props
});

const getSagaMiddleware = (initialState: ApplicationState) => ({
  initialState,
  sagaMiddleware: createSagaMiddleware()
});

export default compose(selectStore, withSagaTask, withAsyncReducers, createStore, getSagaMiddleware);
