import noop from 'lodash/fp/noop';
import { useCallback, useState, FC } from 'react';

import { BlackLink, Info, Link, PaddingLink, ReadMore, Text } from './Wrapper';

export type ReadMoreContentProps = { onReadMoreClick?: () => void };

const Content: FC = () => (
  <>
    <Text>
      For information about how to enable cookies, please see your web browser
      support:
    </Text>
    <Link
      href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en"
      target="_blank"
    >
      Chrome
    </Link>
    <br />
    <Link
      href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
      target="_blank"
    >
      Safari on desktop
    </Link>
    <PaddingLink
      href="https://support.apple.com/guide/iphone/browse-the-web-privately-iphb01fc3c85/ios"
      target="_blank"
    >
      Safari on iPhone
    </PaddingLink>
    <br />
    <Link
      href="https://support.mozilla.org/en-US/kb/disable-third-party-cookies"
      target="_blank"
    >
      Firefox
    </Link>
    <br />
    <BlackLink as="span">Edge</BlackLink>:{' '}
    <Info>
      press the "HUB" icon in the top right of Edge presented as three dots,
      then choose "Settings" at the bottom, and scroll down to "View advanced
      settings" and then if you scroll down you will find "Cookies", choose
      "Don't block cookies" in the drop down menu.
    </Info>
  </>
);

const ReadMoreContent: FC<ReadMoreContentProps> = ({
  onReadMoreClick = noop
}) => {
  const [isReadMoreOpen, setReadMoreOpen] = useState(false);
  const handleReadMoreClick = useCallback(() => {
    setReadMoreOpen(true);
    onReadMoreClick();
  }, [isReadMoreOpen]);

  return isReadMoreOpen ? (
    <Content />
  ) : (
    <ReadMore onClick={handleReadMoreClick}>Read more</ReadMore>
  );
};

export default ReadMoreContent;
